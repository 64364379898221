import { MobileVipPreRegister } from '@/components/account/register/mobile-vip-register';
import VipCodeRegister from '@/components/account/register/vip-code-register';
import LoginCommonLayout from '@/components/layouts/login';
import { Desktop, MobileOrTablet } from '@/components/responsive';
import { useRouter } from '@/core/hooks';
import { EVENT_NAME, EVENT_TRACK } from '@/core/sensorsdata';
import { getFParam, getVipCodeParamValue } from '@/core/utils';
import { getCookie } from '@/core/utils/src/cookie';
import { MediaInfo } from '@/core/utils/src/media-info';
import { useEffect } from 'react';

function Register() {
  const _ru = getCookie('ru');
  const router = useRouter();
  const f = getFParam();
  const vipCode = getVipCodeParamValue('vipCode');
  const ru = router.query?.ru;
  const showVipRegister = router.query?.showVipRegister;
  useEffect(() => {
    EVENT_TRACK(EVENT_NAME.RegisterSignPageEnter, {
      page_title: '注册页',
      invite_method: _ru || vipCode ? router.asPath : '无',
      precise_invite_channel: f || '无',
      invite_code: _ru || vipCode || '无',
      link: window.location.href,
    });
  }, [_ru]);
  const shouldShowVipRegister = vipCode && vipCode?.length < 6 ? false : !!vipCode || !!ru;
  if (shouldShowVipRegister) {
    return (
      <>
        <Desktop forceInitRender={false}>
          <VipCodeRegister />
        </Desktop>
        <MobileOrTablet forceInitRender={false}>
          {showVipRegister ? <VipCodeRegister /> : <MobileVipPreRegister />}
        </MobileOrTablet>
      </>
    );
  }
  return (
    <>
      <LoginCommonLayout loginBoxClassName='refer-login-box' logoJumpId='refer-box'></LoginCommonLayout>
      <style jsx>{`
        :global(.login-bg) {
          overflow: hidden;
          position: relative;
        }
        :global(.refer-login-box) {
          @media ${MediaInfo.tablet} {
            padding: 0 !important;
          }
        }
      `}</style>
    </>
  );
}
export default Register;
