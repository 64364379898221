import { Button } from '@/components/button';
import CommonIcon from '@/components/common-icon';
import LogoIcon from '@/components/header/components/icon/logo-icon';
import Image from '@/components/image';
import { Mobile, Tablet } from '@/components/responsive';
import { getCommonInviteCodeInfoApi } from '@/core/api';
import { useRequestData, useRouter } from '@/core/hooks';
import { LANG } from '@/core/i18n';
import { formatToMonthDayTimeRange, getVipCodeParamValue, MediaInfo } from '@/core/utils';
import css from 'styled-jsx/css';
import { MOBILE_BAR_4IMG_MAP, MOBILE_BAR_5IMG_MAP, MOBILE_VOLUME_HEIGHT } from '../constants';

export const MobileVipPreRegister = () => {
  const router = useRouter();
  const vipCode = getVipCodeParamValue('vipCode');
  const ru = router.query?.ru;
  const [resInfo] = useRequestData(getCommonInviteCodeInfoApi, {
    params: vipCode || ru || '',
  });
  const type = resInfo?.note?.type;
  const onBackHomeClick = () => {
    router.push('/');
  };
  const onCreateAccountClick = () => {
    const updatedQuery = { ...router.query, showVipRegister: true };
    delete updatedQuery.locale;
    router.push({
      pathname: '/register',
      query: updatedQuery,
    });
  };
  const renderDefaultCardContent = () => {
    return (
      <div className='card-content'>
        <p className='title'>
          {LANG('Join {username} on BYDFi Today and Win Welcome Bonus', { username: resInfo?.username || '' })}
        </p>
        <p className='high-light'>{LANG('Up to $8,100')}</p>
        <Image src='/static/images/register/mobile/get-bonus.svg' width={330} height={330} className='banner-img' />
        <style jsx>{styles}</style>
      </div>
    );
  };
  const renderBarContent = () => {
    const rules = resInfo?.note?.rules;
    if (!rules || !rules.length) return null;
    return (
      <div className='bar-container'>
        {rules.map((item, index) => {
          const getBarImg = () => {
            if (rules.length === 4) {
              return MOBILE_BAR_4IMG_MAP[item.level || index + 1];
            }
            return MOBILE_BAR_5IMG_MAP[item.level || index + 1];
          };
          return (
            <div className='bar-column' key={item.trading}>
              <p className='bonus'>{item.bonus}</p>
              <p className='tips'>{LANG('Future Bonus')}</p>
              <Image
                src={getBarImg()}
                width={48}
                height={MOBILE_VOLUME_HEIGHT[item.level || index + 1]}
                className='bar-icon'
              />
              <Tablet>
                <div className='bottom-column'>
                  <p className='label'>
                    {LANG('Deposit')}
                    <span className='value'>{'>' + item.deposit}</span>
                  </p>
                  <p className='label'>
                    {LANG('Volume')}
                    <span className='value'>{'>' + item.trading}</span>
                  </p>
                </div>
              </Tablet>
              <Mobile>
                <div className='bottom-column'>
                  <p className='label'>{LANG('Deposit')}</p>
                  <p className='value'>{'>' + item.deposit}</p>
                  <p className='label'>{LANG('Volume')}</p>
                  <p className='value'>{'>' + item.trading}</p>
                </div>
              </Mobile>
            </div>
          );
        })}
        <style jsx>{styles}</style>
      </div>
    );
  };
  const BANNER_MAP: any = {
    DEPOSIT_TRADING_ADMOUNT: '/static/images/register/mobile/deposit-trading-amount.svg',
    FIRST_DEPOSIT: '/static/images/register/mobile/get-cashback.svg',
    FIRST_TRADING: '/static/images/register/mobile/get-usdt.svg',
  };
  const getBannerImage = () => {
    if (!type) return '/static/images/register/mobile/get-bonus.svg';
    return BANNER_MAP[type];
  };
  // 首次交易送活动/首次充值送活动/充值交易量活动
  const renderCardContentByType = () => {
    const activeTime = resInfo?.note?.activeTime || 0;
    const expireTime = resInfo?.note?.expireTime || 0;
    const isValid = !!activeTime && !!expireTime;
    return (
      <div className='card-content'>
        <p className='title'>{resInfo?.note?.title}</p>
        <p className='high-light'>{resInfo?.note?.subtitle}</p>
        {isValid && (
          <p className='sub-title'>
            {formatToMonthDayTimeRange(resInfo?.note?.activeTime || 0, resInfo?.note?.expireTime || 0)}
          </p>
        )}
        {type === 'DEPOSIT_TRADING_ADMOUNT' ? (
          renderBarContent()
        ) : (
          <Image src={getBannerImage()} width={330} height={330} className='banner-img' />
        )}
        <style jsx>{styles}</style>
      </div>
    );
  };
  const getBannerCardContent = () => {
    if (!type) return renderDefaultCardContent();
    return renderCardContentByType();
  };
  return (
    <div className='mobile-register-container'>
      <div className='mobile-header'>
        <div className='left'>
          <LogoIcon width='62' height='15' />
          <CommonIcon name='common-activity-close-0' size={16} style={{ marginRight: '12px', marginLeft: '12px' }} />
          <Image src={resInfo?.avatar || '/static/images/account/user.png'} width={20} height={20} className='avatar' />
          <span className='name'>{resInfo?.username}</span>
        </div>
        <div className='right'>
          <CommonIcon name='common-back-home-0' size={20} onClick={onBackHomeClick} />
        </div>
      </div>
      <div className='mobile-content'>
        <div className='reg-middle-card'>
          <div className='middle-header'>
            <div className='user-info'>
              <Image
                src={resInfo?.avatar || '/static/images/account/user.png'}
                width={58}
                height={58}
                className='avatar'
              />
              <span className='name'>{resInfo?.username}</span>
            </div>
          </div>
          <div className='bottom-card'>{getBannerCardContent()}</div>
          <div className='rules-card'>
            <p className='title'>{LANG('Rules and Terms:')}</p>
            {resInfo?.note?.notes.map((item) => {
              return (
                <p key={item} className='note'>
                  {item}
                </p>
              );
            })}
          </div>
        </div>
        <div className='reg-vip-program'>
          <div className='logo'>
            <Image src='/static/images/register/mobile/vip-program.svg' width={26} height={26} />
          </div>
          <p className='title'>{LANG('VlP Programme')}</p>
          <p className='sub-title'>{LANG('Get exclusive access to fee discounts & more!')}</p>
        </div>
        <div className='create-account-footer'>
          <Button type='primary' onClick={onCreateAccountClick} className='create-btn'>
            {LANG('Create Account')}
          </Button>
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  .mobile-register-container {
    background-color: var(--spec-background-color-1);
    height: 100%;
    .mobile-header {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 68px;
      background-color: var(--spec-background-color-2);
      border-bottom: 1px solid var(--spec-border-level-1);
      @media ${MediaInfo.mobile} {
        height: 48px;
      }
      .left {
        display: flex;
        align-items: center;
        :global(svg) {
          color: var(--spec-font-color-1);
        }
        .name {
          font-size: 12px;
          font-weight: 500;
          margin-left: 4px;
          color: var(--spec-font-color-1);
        }
      }
    }
    .mobile-content {
      min-height: calc(100vh - 68px);
      background-color: var(--spec-background-color-1);
      @media ${MediaInfo.mobile} {
        margin-bottom: 120px;
      }
      .reg-middle-card {
        background-color: var(--spec-background-color-2);
        margin: 58px 24px 12px;
        border-radius: 8px;
        padding: 16px 12px;
        @media ${MediaInfo.mobile} {
          margin: 48px 16px 12px;
        }
        .middle-header {
          border-bottom: 1px solid var(--spec-border-level-2);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
        }
        .user-info {
          margin-bottom: 55px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: var(--spec-font-color-1);
          .name {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .bottom-card {
          border-bottom: 1px solid var(--spec-border-level-2);
          .card-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 13px 0 28px;
            :global(.bar-container) {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              width: 100%;
              margin-top: 28px;
              @media ${MediaInfo.tablet} {
                padding: 0px 67px;
              }
              :global(.bar-column) {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                :global(.bonus) {
                  color: var(--spec-brand-font-color);
                  font-size: 24px;
                  font-weight: 700;
                  @media ${MediaInfo.mobile} {
                    font-size: 16px;
                  }
                }
                :global(.tips) {
                  font-size: 12px;
                  color: var(--spec-font-color-2);
                  @media ${MediaInfo.mobile} {
                    font-size: 10px;
                    font-weight: 500;
                  }
                }
                :global(.bar-icon) {
                  margin-top: 8px;
                  margin-bottom: 10px;
                  height: 100%;
                  @media ${MediaInfo.mobile} {
                    width: 32px;
                  }
                }
                :global(.label) {
                  font-size: 12px;
                  color: var(--spec-font-color-1);
                  @media ${MediaInfo.mobile} {
                    font-size: 10px;
                    font-weight: 500;
                  }
                }
                :global(.value) {
                  color: var(--spec-brand-font-color);
                  @media ${MediaInfo.mobile} {
                    font-size: 10px;
                    font-weight: 500;
                  }
                }
                :global(.bottom-column) {
                  @media ${MediaInfo.desktop} {
                    padding: 0 18px;
                  }
                }
                &:not(:last-child) {
                  :global(.bottom-column) {
                    @media ${MediaInfo.desktop} {
                      border-right: 1px solid rgba(255, 255, 255, 0.35);
                    }
                  }
                }
              }
            }
            .title {
              font-size: 18px;
              font-weight: 500;
              color: var(--spec-font-color-1);
              margin-bottom: 12px;
              @media ${MediaInfo.mobile} {
                font-size: 16px;
                margin-bottom: 4px;
              }
            }
            .high-light {
              font-size: 44px;
              font-weight: 700;
              margin-bottom: 12px;
              color: var(--spec-brand-font-color);
              text-align: center;
              @media ${MediaInfo.mobile} {
                font-size: 32px;
                margin-bottom: 4px;
              }
            }
            .sub-title {
              font-size: 16px;
              font-weight: 500;
              color: var(--spec-font-color-1);
              margin-top: 4px;
              @media ${MediaInfo.mobile} {
                font-size: 12px;
              }
            }
            :global(.banner-img) {
              @media ${MediaInfo.mobile} {
                width: 250px;
                height: 250px;
                margin-top: 28px;
              }
            }
          }
        }
        .rules-card {
          padding: 16px 0 18px;
          .title {
            color: var(--spec-font-color-1);
            font-weight: 600;
          }
          .note {
            color: var(--spec-font-color-2);
            font-size: 16px;
            font-weight: 500;
            @media ${MediaInfo.mobile} {
              font-size: 14px;
            }
          }
        }
      }
    }
    .reg-vip-program {
      background-color: var(--spec-background-color-2);
      margin-top: 12px;
      margin-bottom: 34px;
      height: 160px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media ${MediaInfo.mobile} {
        margin-left: 16px;
        margin-right: 16px;
      }
      @media ${MediaInfo.tablet} {
        margin-left: 24px;
        margin-right: 24px;
      }

      .logo {
        background-color: var(--spec-background-color-4);
        width: 42px;
        height: 42px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
      }
      .title {
        font-size: 20px;
        font-weight: 500;
        color: var(--spec-font-color-1);
      }
      .sub-title {
        color: var(--spec-font-color-2);
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        @media ${MediaInfo.mobile} {
          font-size: 14px;
          margin-top: 8px;
        }
      }
    }
    .create-account-footer {
      background-color: var(--spec-background-color-2);
      padding: 24px 24px 68px;
      @media ${MediaInfo.mobile} {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
      }
      @media ${MediaInfo.mobile} {
        padding: 24px 16px 40px;
      }
      :global(.create-btn) {
        height: 48px;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        @media ${MediaInfo.mobile} {
          height: 40px;
        }
      }
    }
  }
`;
